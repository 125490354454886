import React, { Component } from 'react';
import { Container, Grid, Typography, Link, Toolbar, Button, Tabs, Tab } from '@material-ui/core';
import "./HomePage.css"
export default class HomePage extends Component {
    render() {
        return (
            <Container maxWidth="xl" className="homepage">
                <Grid container className="grid-root" alignItems="center" justify="center" maxWidth>
                    <Grid item xs={8}>
                        <Typography className="title" variant="h2" align="left" gutterBottom>
                            Patrick Hum
                        </Typography>
                        <Grid container spacing={2} justify="left">
                            <Grid item>
                                <Typography variant="body1" gutterBottom>
                                    <Link href="https://linkedin.com/in/patrickhum/" className="info-link">LinkedIn</Link>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" gutterBottom>
                                    <Link href="https://github.com/patzhum" className="info-link">Github</Link>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" gutterBottom>
                                    <Link href="/Resume - Patrick Hum.pdf" download className="info-link">Resumé</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* <Grid className="body-button-container" container direction="column" alignContent="center">
                            <Grid item>
                                <Button variant="text" fullWidth>Experience</Button>
                                <Button variant="text" fullWidth>Projects</Button>
                            </Grid>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Container>
        )
    }
}