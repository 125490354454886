import React from 'react';
import logo from './logo.svg';
import './App.css';
import HomePage from './pages/HomePage';

function App() {
  return (
    <HomePage/>
  );
}

export default App;
